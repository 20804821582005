/** @jsxImportSource react */
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import FontAwesomeIcon from './FontAwesomeIcon'
import HeaderNavLink from './HeaderNavLink'

export default function ShopNavigationButton(props: PropsWithChildren) {
  const shopNavRef = useRef<HTMLElement | null>(null)
  const [show, setShow] = useState(false)
  const toggle = (toShow?: boolean) => setShow(toShow ?? !show)

  useEffect(() => {
    shopNavRef.current = document.getElementById('shop-navigation') as HTMLElement
    const handler = () => setShow(false)

    shopNavRef.current?.addEventListener('mouseleave', handler)
    return () => shopNavRef.current?.removeEventListener('mouseleave', handler)
  }, [])

  useEffect(() => {
    if (show) {
      document.documentElement.setAttribute('data-modal', 'open')
      document.documentElement.setAttribute('data-menu', 'open')
    } else {
      document.documentElement.removeAttribute('data-modal')
      document.documentElement.removeAttribute('data-menu')
    }
  }, [show])

  return (
    <>
      <div className="u-flex u-items-center u-gap-1" onMouseEnter={() => toggle(true)}>
        <HeaderNavLink url="/products">Shop</HeaderNavLink>
        <button
          className="u-leading-0 u--mt-1"
          onClick={() => toggle()}
          aria-haspopup="true"
          aria-label="Shop navigation"
          aria-expanded={show}
        >
          <FontAwesomeIcon icon="chevron-down" size="sm" />
        </button>
      </div>
      <div
        className="show full-width u-absolute u-top-0 u-z-[-1] u-bg-white u-pb-[60px] u-pt-[104px] u-drop-shadow-md"
        data-show={show}
      >
        {props.children}
      </div>
    </>
  )
}
