/** @jsxImportSource react */
import type { PropsWithChildren } from 'react'

export interface Props {
  url: string
}

export default function HeaderNavLink(props: PropsWithChildren<Props>) {
  return (
    <a href={props.url} className="u-uppercase">
      {props.children}
    </a>
  )
}
